import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Event as EventIcon,
  GpsFixed,
  Home as HomeIcon,
  Menu as MenuIcon,
  MyLocation,
} from "@material-ui/icons";
import React, { useEffect } from "react";
import { BiSupport } from "react-icons/bi";
import { FiCircle, FiLogOut } from "react-icons/fi";
import { useHistory, useLocation } from "react-router";
import { useStateValue } from "../../providers/StateProvider";
import { logout } from "../../services/auth";
import { hideMaskCPF } from "../../utils/cpfMask";
import { nameSplitOne, nameSplitTwo } from "../../utils/nameFormat";
import { useStyles } from "./style";

export default function Header(): JSX.Element {
  const [{ user, currentTicketOffice }] = useStateValue();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const matchesMobile = useMediaQuery("(min-width:576px)");
  const [anchorElUser, setAnchorElUser] =
    React.useState<HTMLButtonElement | null>(null);
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null);

  const goPage = (page: string) => {
    history.push(page);
    setAnchorElMenu(null);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: "user" | "menu"
  ) => {
    if (type === "user") {
      setAnchorElUser(event.currentTarget);
    }
    if (type === "menu") {
      setAnchorElMenu(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorElUser(null);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  const logoutUser = () => {
    logout();

    setAnchorElUser(null);
  };
  const changeTicketOffice = () => {
    localStorage.removeItem("gov_ticket_office");
    history.push("/");
  };

  useEffect(() => {
    if (!user && !currentTicketOffice) {
      localStorage.setItem("pathname_redirect_gov", pathname);
      history.push("/");
    }
  }, [user, currentTicketOffice, history, pathname]);

  return (
    <Box className={classes.root}>
      {!matchesMobile && (
        <>
          <IconButton onClick={(e): void => handleClick(e, "menu")} color="primary">
            <MenuIcon />
          </IconButton>

          <Popover
            open={Boolean(anchorElMenu)}
            anchorEl={anchorElMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box>
              <MenuItem
                className={classes.menuItem}
                style={{ width: 250, wordBreak: "break-word" }}
              >
                <GpsFixed color="primary" />
                <Typography
                  className={classes.textMenuItem}
                  style={{
                    width: 220,
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  GUICHÊ: {currentTicketOffice?.ordem || ""}{" "}
                  {currentTicketOffice?.nome || ""}{" "}
                  {currentTicketOffice?.setor &&
                    currentTicketOffice?.setor?.setor?.nome}{" "}
                  | UNIDADE:{" "}
                  {currentTicketOffice?.setor?.unidade?.nome || currentTicketOffice?.unidade?.nome}
                </Typography>
              </MenuItem>

              <Divider />

              <MenuItem
                className={classes.menuItem}
                style={{
                  color: "#004F9F",
                }}
                onClick={() => {
                  goPage("/home");
                }}
              >
                <HomeIcon />
                <Typography className={classes.textMenuItem}>Início</Typography>
              </MenuItem>
              <Divider />
              {currentTicketOffice &&
                currentTicketOffice.tipo === "Presencial" && (
                  <MenuItem
                    className={classes.menuItem}
                    style={{
                      color: "#004F9F",
                    }}
                    onClick={() => goPage("/agendamento")}
                  >
                    <EventIcon />
                    <Typography className={classes.textMenuItem}>
                      Atendimentos
                    </Typography>
                  </MenuItem>
                )}
              {currentTicketOffice && currentTicketOffice.tipo === "Online" && (
                <MenuItem
                  className={classes.menuItem}
                  style={{
                    color: "#004F9F",
                  }}
                  onClick={() => goPage("/atendimento")}
                >
                  <EventIcon />
                  <Typography className={classes.textMenuItem}>
                    Agendamentos
                  </Typography>
                </MenuItem>
              )}
              {currentTicketOffice && currentTicketOffice.tipo === "Interno" && (
                <MenuItem
                  className={classes.menuItem}
                  style={{
                    color: "#004F9F",
                  }}
                  onClick={() => goPage("/agendamento_interno")}
                >
                  <EventIcon />
                  <Typography className={classes.textMenuItem}>
                    Atendimentos
                  </Typography>
                </MenuItem>
              )}
              {currentTicketOffice && currentTicketOffice.tipo === "Consulta" && (
                <MenuItem
                  className={classes.menuItem}
                  style={{
                    color: "#004F9F",
                  }}
                  onClick={() => goPage("/consulta_agendamento")}
                >
                  <EventIcon />
                  <Typography className={classes.textMenuItem}>
                    Atendimentos
                  </Typography>
                </MenuItem>
              )}
              {currentTicketOffice && currentTicketOffice.tipo === "Diario" && (
                <MenuItem
                  className={classes.menuItem}
                  style={{
                    color: "#004F9F",
                  }}
                  onClick={() => {
                    goPage("/atendimento_diario");
                  }}
                >
                  <EventIcon />
                  <Typography className={classes.textMenuItem}>
                    Atendimentos
                  </Typography>
                </MenuItem>
              )}
            </Box>
          </Popover>
        </>
      )}

      <Box className={classes.textTicketOffice}>
        {matchesMobile ? (
          <Typography className={classes.textHeader}>
            GUICHÊ: {currentTicketOffice?.ordem || ""}{" "}
            {currentTicketOffice?.nome || ""}{" "}
            {currentTicketOffice?.setor &&
              currentTicketOffice?.setor?.setor?.nome}{" "}
            | UNIDADE:{" "}
            {currentTicketOffice?.setor?.unidade?.nome || currentTicketOffice.unidade?.nome}
          </Typography>
        ) : (
          <span
            style={{
              background: "#787878",
              color: "#fff",
              textTransform: "uppercase",
              fontFamily: "Roboto",
              marginRight: 10,
              padding: 5,
              fontSize: 13,
              borderRadius: 5,
            }}
          >
            {currentTicketOffice && currentTicketOffice?.tipo === "Diario"
              ? "Orgânico"
              : currentTicketOffice?.tipo === "Interno" ? "Complementar" : currentTicketOffice?.tipo}
          </span>
        )}
      </Box>

      <Box className={classes.userMenus}>
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box>
            {user && (
              <MenuItem
                style={{
                  backgroundColor: "#F3F6FA",
                  // minHeight: 73,
                }}
              >
                <Avatar className={classes.avatar} />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography className={classes.textName} color="primary">
                    {matchesMobile
                      ? nameSplitTwo(user?.nome?.toLowerCase())
                      : nameSplitOne(user?.nome?.toLowerCase())}
                  </Typography>
                  <Typography color="primary" className={classes.textCpf}>
                    {hideMaskCPF(user.cpf || "")}
                  </Typography>
                </Box>
              </MenuItem>
            )}
            <Divider />
            <MenuItem className={classes.menuItem}>
              <BiSupport />
              <Typography className={classes.textMenuItem}>
                GUICHÊ: {currentTicketOffice?.ordem || ""}{" "}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem className={classes.menuItem}>
              <MyLocation />
              <Typography className={classes.textMenuItem}>
                Unidade:{currentTicketOffice?.setor?.unidade?.nome || currentTicketOffice?.unidade?.nome}{" "}{" "}
              </Typography>
            </MenuItem>

            <Divider />
            <MenuItem className={classes.menuItem} onClick={changeTicketOffice}>
              <FiCircle />
              <Typography className={classes.textMenuItem}>Trocar Guichê</Typography>
            </MenuItem>
            <Divider />
            <MenuItem className={classes.menuItem} onClick={logoutUser}>
              <FiLogOut />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
            <Divider />
          </Box>
        </Popover>
        {matchesMobile && (
          <span
            style={{
              background: "#787878",
              color: "#fff",
              textTransform: "uppercase",
              fontFamily: "Roboto",
              marginRight: 10,
              padding: 5,
              fontSize: 13,
              borderRadius: 5,
            }}
          >
            {currentTicketOffice && currentTicketOffice?.tipo === "Diario"
              ? "Orgânico"
              : currentTicketOffice?.tipo === "Interno" ? "Complementar" : currentTicketOffice?.tipo}
          </span>
        )}

        {user && (
          <Button color="primary" onClick={(e): void => handleClick(e, "user")}>
            <Avatar className={classes.avatar} />
          </Button>
        )}
      </Box>
    </Box>
  );
}

import { createContext, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useWebSocket from 'react-use-websocket'
import { getAtendimentoHistorico } from '../../../services/atendimento'
import { getFromLocalStorage } from '../../../utils/getFromLocalStorage'
import { removeFromLocalStorage } from '../../../utils/removeFromLocalStorage'
import { STORAGE_KEY, STORAGE_QUEUE } from '../utils/constants'

export type Ticket = {
  guiche: string
  nome: string
  senha: string
  servico_titulo: string
  horario: string
}

export type QueueContextProps = {
  ticketQueue: Ticket[]
  recentTickets: Ticket[]
  currentTicket: Ticket | undefined
  loadingRecentTickets: boolean
}

export const QueueManagementContext = createContext<QueueContextProps | null>(null)

export function QueueManagementProvider({ children }: { children: ReactNode }): JSX.Element {
  const { id: roomId } = useParams<{ id: string }>()
  const unitData = localStorage.getItem('gov_ticket_office')
  const unit = unitData ? JSON.parse(unitData) : null
  const slug_unidade = unit?.setor?.unidade?.slug_unidade || unit?.unidade?.slug_unidade
  const initialQueue = getFromLocalStorage<Ticket[]>(STORAGE_QUEUE)
  const [ticketQueue, setTicketQueue] = useState<Ticket[]>(initialQueue || [])
  const [recentTickets, setRecentTickets] = useState<Ticket[]>([])
  const [loadingRecentTickets, setLoadingRecentTickets] = useState(true)
  const [currentTicket, setCurrentTicket] = useState<Ticket | undefined>(undefined)
  const [socketParams, setSocketParams] = useState({
    hostname: roomId || 'test',
    room_name: String(slug_unidade) || ''
  })
  const [socketInitialized, setSocketInitialized] = useState(false)

  const updateTicketQueueStorage = (ticketsData: Ticket[]): void => {
    localStorage.setItem(STORAGE_QUEUE, JSON.stringify([...ticketsData]))
  }

  const getHistoryTickets = async () => {
    try {
      const token: string | null = localStorage.getItem("gov_access_token_sso");
      const response = await getAtendimentoHistorico(token, slug_unidade);
      const { data } = response;
      if (data) {
        const lastTicketCall = data.results[0]

        if (!currentTicket && lastTicketCall) {
          setCurrentTicket(lastTicketCall)
        }

        const tickets = data.results.map((ticket) => ({
          guiche: ticket.guiche,
          nome: ticket.nome,
          senha: ticket.senha,
          servico_titulo: ticket.servico_titulo,
          horario: ticket.horario
        }));

        setRecentTickets(tickets);
      }
    } catch (error) {
      console.error('Erro ao obter o histórico de tickets:', error);
    } finally {
      setLoadingRecentTickets(false)
    }
  };

  useEffect(() => {
    if (recentTickets.length === 0) {
      getHistoryTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentTickets])

  const clearStorageKeys = () => {
    removeFromLocalStorage(STORAGE_KEY)
    removeFromLocalStorage(STORAGE_QUEUE)
  }

  const SOCKET_URL = (window as any)._env_.REACT_APP_WEBSOCKET_PAINEL_WS ?? ''

  const { getWebSocket, readyState, sendJsonMessage } = useWebSocket(SOCKET_URL, {
    queryParams: socketParams,
    onOpen: () => {
      if (!socketInitialized) {
        toast.success('Conexão estabelecida com sucesso!')
        setSocketInitialized(true)
      }
    },
    onClose: () => {
      toast.warn('Conexão perdida. Tentando reconectar...')
      setSocketInitialized(false)
    },
    onError: (error: any) => {
      toast.error(`Erro na conexão WebSocket: ${error.message}`)
    },
    filter: (message) => {
      if (message.data) {
        const passwordsData = new Set(ticketQueue.map(ticket => JSON.stringify(ticket)))
        const data: any = JSON.parse(message.data)

        const calledTime = new Intl.DateTimeFormat("pt-BR", {
          timeZone: "America/Campo_Grande",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date());

        if (!passwordsData.has(JSON.stringify(data.payload))) {
          const ticket: Ticket = {
            ...data.payload,
            horario: calledTime
          }

          passwordsData.add(JSON.stringify(ticket))
          const updatedQueue = Array.from(passwordsData).map(ticket => JSON.parse(ticket))
          updateTicketQueueStorage(updatedQueue)
          setTicketQueue(updatedQueue)
        }
      }
      return true
    }
  })

  useEffect(() => {
    if (readyState !== WebSocket.OPEN) return

    const interval = setInterval(() => {
      sendJsonMessage({ type: 'PING' })
    }, 2 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [readyState, sendJsonMessage])

  useEffect(() => {
    const interval = setInterval(() => {
      if (ticketQueue.length > 0) {
        const nextPassword = ticketQueue[0]
        setCurrentTicket(nextPassword)
        setRecentTickets((prevPasswords) => {
          const passwordsCopy = structuredClone(prevPasswords)
          if (passwordsCopy.length > 0 && nextPassword.senha === passwordsCopy[0].senha) {
            passwordsCopy.shift()
          }
          passwordsCopy.unshift(nextPassword)
          if (passwordsCopy.length > 20) {
            passwordsCopy.pop()
          }
          return passwordsCopy
        })
        const nextPasswordsQueue = ticketQueue.slice(1)
        updateTicketQueueStorage(nextPasswordsQueue)
        setTicketQueue(nextPasswordsQueue)
      } else {
        clearInterval(interval)
      }
    }, 5 * 1000)

    return () => clearInterval(interval)
  }, [ticketQueue])

  useEffect(() => {
    if (roomId) {
      setSocketParams((prevParams) => ({
        ...prevParams,
        hostname: roomId || 'test',
        room_name: slug_unidade || ''
      }))
    }
  }, [roomId, slug_unidade])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (readyState === WebSocket.OPEN) {
        getWebSocket()?.close()
        console.log('WebSocket fechado antes de sair')
      }

      clearStorageKeys()

      event.preventDefault()
      event.returnValue = ''
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [getWebSocket, readyState])

  const context: QueueContextProps = {
    recentTickets,
    ticketQueue,
    currentTicket,
    loadingRecentTickets
  }

  return (
    <QueueManagementContext.Provider value={context}>{children}</QueueManagementContext.Provider>
  )
}
